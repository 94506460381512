<template>
    <div class="w-90 center mv4">
        <div class="flex scrollmenu link-container">
            <router-link active-class="active-header" :to="{ name: 'EntitiesArchive' }">
                <div class="pv3 pa3 w-100 b">Entities</div>
            </router-link>
            <router-link active-class="active-header" :to="{ name: 'ProductsArchive' }">
                <div class="pv3 pa3 w-100 b">Products</div>
            </router-link>
            <router-link active-class="active-header" :to="{ name: 'TransactionsArchive' }">
                <div class="pv3 pa3 w-100 b">Transactions</div>
            </router-link>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'ArchivesHeader',

    setup() {
        const store = useStore()
        const role = computed(() => store?.state?.Auth?.role)

        return {
            role
        }
    }
}
</script>

<style scoped>
.active-header {
    background: white;
    color: #132c8c !important;
}
.link-container {
    background: #132c8c;
    color: white;
    width: fit-content;
    border: 2px solid #132c8c;
    border-radius: 20px;
    background-color: #132c8c;
}
.link-container a{
    color: white;
}
</style>
