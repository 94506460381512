<template>
    <settings-wrapper>
        <template v-slot:child>
            <archives-header />
            <div class="box-border w-80-l w-90 center mt4">
                <div class="pa3 scrollmenu">
                    <table v-cloak v-if="products?.data?.length" cellspacing="0" class="table radius8">
                        <thead>
                            <tr style="background-color: #F4F7FF;">
                                <th class="w-10 borderTop">Code</th>
                                <th class="tl borderTop">Name</th>
                                <th class="tl borderTop">Quantity</th>
                                <th class="tl borderTop">Type</th>
                                <th class="tl borderTop">Cost</th>
                                <th class="tl borderTop">Total</th>
                                <th class="tl borderTop">Deleted At</th>
                                <th class="tl borderTop">Deleted By</th>
                                <th class="w-10 borderTop">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(product, index) in products?.data" :key="index">
                                <td class="tl borderTop">{{ product._id.toUpperCase().slice(1, 10) }}</td>
                                <td class="tl flex items-center borderTop">
                                    <img
                                        :src="product?.imageUrl || require('@/assets/images/inventory-default.png')"
                                        class="pr1 h-25 w-25 cover"
                                        style="object-position: center; height: 20px; width: 20px; gap: 1rem"
                                        :alt="product.name"
                                    />
                                    <span class="font-w2">{{ product.name }}</span>
                                </td>
                                <td class="tc borderTop">
                                    {{
                                        formatQuantity(
                                            product?.purchase_qty
                                                ? parseFloat(product?.purchase_qty) - parseFloat(product?.sales_qty || 0)
                                                : 0
                                        )
                                    }}
                                </td>
                                <td class="tc borderTop">
                                    <span v-if="product.type === 'RAW_MATERIAL'" class="badge badge-pill-2 badge-danger" title="Raw Material">R</span>
                                    <span v-else-if="product.type === 'PRODUCTION'" class="badge badge-pill-2 badge-dark" title="Finished Product">F</span>
                                    <span v-else class="badge badge-pill-2 badge-info" title="Product">P</span>
                                </td>
                                <td class="tl borderTop">{{ formatAmount(product?.buyingPrice) }}</td>
                                <td class="tl borderTop">
                                    <span v-if="product?.purchase_qty && product?.sales_qty">
                                        {{
                                            formatAmount(
                                                (parseFloat(product?.purchase_qty) - parseFloat(product?.sales_qty)) *
                                                    product?.buyingPrice
                                            )
                                        }}
                                    </span>
                                    <span v-else>
                                        {{ formatAmount(product.purchase_qty * product?.buyingPrice) }}
                                    </span>
                                </td>
                                <td class="tl borderTop">{{ formatDate(product?.deletedAt) }}</td>
                                <td class="tl borderTop">{{ product?.deletedBy?.name || '-' }}</td>                            
                                <td class="tc borderTop">
                                    <a href="" @click.prevent="restoreProduct(product._id)" title="Restore">
                                        <img :src="require('@/assets/images/Reload.svg')" alt="restore" />
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <TableEmptyState v-else :data-array="archives" />
                </div>
            </div>
        </template>
    </settings-wrapper>
</template>

<script>
import { computed, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { formatAmount, formatQuantity, formatDate } from '@/utils/lib'
import SettingsWrapper from '@/views/Settings/widgets/SettingsWrapper'
import ArchivesHeader from '@/views/Settings/widgets/ArchivesHeader'
import TableEmptyState from '@/components/TableEmptyState'

export default {
    name: 'AccountSettings',
    components: { ArchivesHeader, SettingsWrapper, TableEmptyState },

    setup() {
        const store = useStore()
        const products = computed(() => store?.state.Inventory.archivedProducts)

        const initialState = () => ({
            stockLevel: 1
        })
        const state = reactive(initialState())

        const setBGAndTextColor = entityItem => {
            const item = entityItem?.roles?.[0]

            switch (item) {
                case 'customer':
                    return 'background-color: #edf0fd; color: #132c8c;'

                case 'vendor':
                    return 'background-color: #ce541b; color: white;'

                case 'debtor':
                    return 'background-color: #14c773; color: white;'

                case 'investor':
                    return 'background-color: #132c8c; color: white;'

                case 'creditor':
                    return 'background-color: #eb2d36; color: white;'

                case 'supplier':
                    return 'background-color: #662deb; color: white;'

                default:
                    return 'background-color: #662deb; color: white;'
            }
        }

        const entityRoles = ['customer', 'vendor', 'debtor', 'investor', 'creditor', 'supplier']

        const restoreProduct = id => {
            store.dispatch('Inventory/restoreProduct', id)
        }

        onMounted(() => {
            store.dispatch('Inventory/getArchivedProducts')
        })

        return {
            state,
            products,
            entityRoles,
            setBGAndTextColor,
            restoreProduct,
            formatAmount,
            formatQuantity,
            formatDate
        }
    }
}
</script>

<style scoped>
#button-update {
    position: relative;
    padding: 15px 50px;
    margin: 20px;
    /* display: flex; */
    /* justify-content: flex-end; */
}
.borderTop{
    border-top: none !important;
    border-bottom: none !important;
}
</style>
